
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  name: "Widget1",
  components: {},
  setup() {
    const chartOptions = {
      chart: {
        type: "area",
        height: 150,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: ["#20D489"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
        ],
        tooltip: {
          enabled: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: "Poppins",
        },
        y: {
          formatter: (val) => {
            return "$" + val + " thousands";
          },
        },
        marker: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        min: 0,
        max: 60,
        labels: {
          show: false,
          style: {
            colors: "#B5B5C3",
            fontSize: "12px",
            fontFamily: "Poppins",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.6,
          stops: [0, 100],
        },
      },
      colors: ["#20D489"],
      markers: {
        colors: ["#E4FFF4"],
        strokeColor: ["#20D489"],
        strokeWidth: 3,
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
    };

    const show = ref("week");

    const weekStats = [
      {
        name: "Net Profit",
        data: [30, 30, 43, 43, 34, 34, 26, 26, 47, 47],
      },
    ];
    const monthStats = [
      {
        name: "Net Profit",
        data: [30, 30, 35, 35, 50, 50, 40, 40, 32, 32],
      },
    ];
    const week = [
      {
        svg: "media/svg/brand-logos/telegram-2.svg",
        title: "ThumbsUp HTML",
        desc: "Most Successful Fellas",
        profit: "+280$",
      },
      {
        svg: "media/svg/brand-logos/plurk.svg",
        title: "Pipeline Theme",
        desc: "Most Successful Fellas",
        profit: "+82$",
      },
      {
        svg: "media/svg/misc/puzzle.svg",
        title: "Finder Admin",
        desc: "Most Successful Fellas",
        profit: "+4500$",
      },
    ];
    const month = [
      {
        svg: "media/svg/brand-logos/telegram-2.svg",
        title: "ThumbsUp HTML",
        desc: "Most Successful Fellas",
        profit: "+280$",
      },
      {
        svg: "media/svg/brand-logos/plurk.svg",
        title: "Finder Admin",
        desc: "Most Successful Fellas",
        profit: "+4500$",
      },
      {
        svg: "media/svg/misc/puzzle.svg",
        title: "Pipeline Theme",
        desc: "Most Successful Fellas",
        profit: "+82$",
      },
    ];

    const dataToShow = computed(() => {
      if (show.value === "week") return week;
      if (show.value === "month") return month;
      return week;
    });

    const statsToShow = computed(() => {
      if (show.value === "week") return weekStats;
      if (show.value === "month") return monthStats;
      return weekStats;
    });

    return {
      chartOptions,
      show,
      weekStats,
      monthStats,
      week,
      month,
      dataToShow,
      statsToShow,
    };
  },
});
