import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xxl-8" }
const _hoisted_2 = { class: "col-xl-6" }
const _hoisted_3 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedsWidget1 = _resolveComponent("FeedsWidget1")!
  const _component_FeedsWidget2 = _resolveComponent("FeedsWidget2")!
  const _component_FeedsWidget3 = _resolveComponent("FeedsWidget3")!
  const _component_FeedsWidget4 = _resolveComponent("FeedsWidget4")!
  const _component_TablesWidget4 = _resolveComponent("TablesWidget4")!
  const _component_MixedWidget1 = _resolveComponent("MixedWidget1")!
  const _component_ListWidget2 = _resolveComponent("ListWidget2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FeedsWidget1),
      _createVNode(_component_FeedsWidget2),
      _createVNode(_component_FeedsWidget3),
      _createVNode(_component_FeedsWidget4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TablesWidget4),
      _createVNode(_component_MixedWidget1),
      _createVNode(_component_ListWidget2)
    ])
  ]))
}