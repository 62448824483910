
import { defineComponent, toRef, computed, ref } from "vue";
import { Storage } from "aws-amplify";

export default defineComponent({
  name: "widget-3",
  components: {},
  props: {
    avatar: String,
    title: String,
    subtitle: String,
    website: String,
    bio: String,
    bannerSmall: Boolean,
    image: String,
    url: String,
  },
  async setup(props) {
    const avatar = toRef(props, "avatar");
    const title = toRef(props, "title");
    const subtitle = toRef(props, "subtitle");
    const bio = toRef(props, "bio");
    const bannerSmall = toRef(props, "bannerSmall");
    const image = toRef(props, "image");
    const url = toRef(props, "url");

    const avatarInfo = computed(() => {
      return avatar.value ? avatar.value : "media/svg/logo/colored/tower.svg";
    });

    const titleText = computed(() => {
      return title.value ? title.value : "Parque em POA";
    });

    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "Porto Alegre";
    });

    const bannerSmallCondition = computed(() => {
      return bannerSmall.value ? bannerSmall.value : ref<boolean>(false);
    });

    const bioInfo = computed(() => {
      return bio.value ? bio.value : "";
    });

    const storageFile = image.value ? image.value : "";
    const imaged = await Storage.get(storageFile, {
      level: "public",
    });

    const imageInfo = computed(() => {
      return imaged ? imaged : "media/stock/900x600/3.jpg";
    });

    const urlInfo = computed(() => {
      return url.value ? url.value : "";
    });

    return {
      avatarInfo,
      titleText,
      subtitleText,
      bioInfo,
      bannerSmallCondition,
      imageInfo,
      urlInfo,
    };
  },
  methods: {
    openTool(path) {
      this.$router.replace("/ai-tools/" + path);
    },
  },
});
